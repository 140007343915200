<script lang="ts">
    import {MediaFormEntry, MediaUploadFormEntry} from "./interface"
    import FormTypeErrors from "@shared/Form/FormTypeErrors.svelte"
    import {throttle} from "lodash-es"
    import {AnimatePresence, Motion} from "svelte-motion"
    import {Easing} from "@utils/Easing"
    import {getImagePreview, getPreviewIcon, isImage, pickFileDialog, uploadFile, UploadStatus} from "./utils"
    import tooltip from "@utils/Action/Tooltip"
    import {createEventDispatcher} from "svelte"
    import imageCompression from "browser-image-compression"

    export let entry: MediaUploadFormEntry

    const dispatch = createEventDispatcher()
    const child: MediaFormEntry | null = entry.children.collection.children[0] ?? null

    let draggingFile = false
    let isOverUploadSpace = false
    let dragActiveTimeout
    let dropZoneEl: HTMLElement
    let pickingFile = false

    let mediaName: string = child?.children?.name?.value ?? ""
    let mediaId: string | undefined = child?.children?.id?.value ?? undefined
    let mediaDescription: string = child?.children?.description?.value ?? ""
    let mediaPosition: number = parseInt(child?.children?.position?.value ?? "0")

    let uploadingStatus: UploadStatus = {
        previewUrl: child?.data?.extension && child?.data?.url
                ? isImage(child.data.extension)
                        ? child.data.url
                        : getPreviewIcon(child.data.extension)
                : null,
        percentage: child?.data ? 100 : 0,
        id: undefined,
        status: child?.data ? "uploaded" : null,
        size: 0,
        chunk: {
            total: 0,
            current: 0
        }
    }

    const handleDocumentDragEnter = throttle(() => {
        if(entry.disabled) {
            return
        }

        if (entry.errors.length) {
            entry.errors = []
        }

        draggingFile = true

        if (dragActiveTimeout) {
            clearTimeout(dragActiveTimeout)
        }

        dragActiveTimeout = setTimeout(() => {
            draggingFile = false
            isOverUploadSpace = false
            if(dragActiveTimeout) {
                clearTimeout(dragActiveTimeout)
            }
        }, 2000)
    }, 200, {
        leading: true,
        trailing: true,
    })

    const handleDropFile = (e: DragEvent): void => {
        if(entry.disabled) {
            return
        }

        draggingFile = false
        isOverUploadSpace = false

        if (e.dataTransfer.files.length > 0) {
            processFile(e.dataTransfer.files.item(0))
        }
    }

    const processFile = async (file: File): Promise<void> => {
        const nameBackup = file.name
        const extension = file.name.split(".").pop().toLowerCase()
        if (entry.extra.allowed_extensions !== null) {
            if (!entry.extra.allowed_extensions.includes(extension)) {
                entry.errors = ["Tento typ souboru není povolen", "Povolené typy - " + entry.extra.allowed_extensions.join(", ")]
                return
            }
        }

        entry.errors = []

        uploadingStatus = {
            ...uploadingStatus,
            status: null
        }

        if(["jpg", "jpeg", "png"].includes(extension)) {
            uploadingStatus = {
                ...uploadingStatus,
                status: "compressing"
            }

            try {
                file = await imageCompression(file, {
                    useWebWorker: true,
                    preserveExif: true,
                    alwaysKeepResolution: true,
                })

                //@ts-ignore
                file.name = nameBackup
            } catch (error) {
                console.log(error);
            }
        }

        getImagePreview(file, extension).then(previewUrl => {
            uploadingStatus = {
                ...uploadingStatus,
                previewUrl
            }
        })

        uploadingStatus = {
            ...uploadingStatus,
            status: null
        }

        mediaName = file.name.substring(0, file.name.lastIndexOf("."))

        uploadFile({
            id: mediaId,
            file,
            extension,
            storageType: entry.extra.storage_type,
            onProgress: (status) => {
                uploadingStatus = {
                    ...uploadingStatus,
                    ...status,
                    percentage: Math.round((status.chunk.current / status.chunk.total) * 100),
                }
            }
        }, (name: string, data: any) => dispatch(name, data)).then(status => {
            if (uploadingStatus.status !== null) {
                mediaId = status.id
            }
        })
    }

    const handleDropzoneClick = (): void => {
        if(entry.disabled || pickingFile) {
            return
        }

        if (["uploaded", null].includes(uploadingStatus.status)) {
            pickingFile = true
            pickFileDialog().then(files => {
                processFile(files[0])
            }).catch(() => {}).finally(() => pickingFile = false)
        }
    }

    const resetUpload = (): void => {
        mediaName = ""
        mediaId = undefined

        uploadingStatus = {
            previewUrl: null,
            percentage: 0,
            id: undefined,
            status: null,
            size: 0,
            chunk: {
                total: 0,
                current: 0
            }
        }

        dispatch("reset")
    }
</script>

<svelte:body on:dragenter={handleDocumentDragEnter}/>

{#if entry.label !== null}
    <label class="font-bold text-neutral-800 relative mb-1" {...entry.labelAttr}>
        {@html entry.label}
        {#if !entry.disabled && entry.required}
            <span class="absolute ml-1 text-red-800">*</span>
        {/if}
    </label>
{/if}

<div class="flex flex-col">
    <Motion let:motion
            animate={{
                scale: ["initializing", "uploading"].includes(uploadingStatus.status) === false && isOverUploadSpace ? 1.1 : 1,
            }}
            transition={{
                type: "timing",
                duration: .3,
                ease: Easing.easeOutQuint,
            }}
    >
        <div use:motion
             class="flex bg-neutral-100 rounded-md w-96 h-48 relative overflow-hidden {['uploading', 'initializing'].includes(uploadingStatus.status) || entry.disabled ? '' : 'cursor-pointer'}"
             bind:this={dropZoneEl}
             on:click={handleDropzoneClick}
             on:dragenter={() => isOverUploadSpace = true}
             on:dragleave={() => isOverUploadSpace = false}
             on:dragover|preventDefault
             on:drop|preventDefault={handleDropFile}
        >
            {#if !entry.disabled}
                <AnimatePresence show={uploadingStatus.status === "uploaded"}>
                    <Motion let:motion
                            initial={{
                                opacity: 0
                            }}
                            animate={{
                                opacity: 1
                            }}
                            exit={{
                                opacity: 0
                            }}
                            transition={{
                                type: "timing",
                                duration: .5,
                                ease: Easing.easeOutQuint,
                            }}
                    >
                        <div use:motion
                             class="absolute top-2 right-2 p-1 bg-white rounded-md z-50 shadow-lg"
                             on:click|preventDefault|stopPropagation={resetUpload}
                             use:tooltip={{content: "Odstranit", placement: "left"}}
                        >
                            <img src="/image/delete.svg" class="object-contain w-5 h-5" alt="remove image"/>
                        </div>
                    </Motion>
                </AnimatePresence>
            {/if}
            <AnimatePresence show={uploadingStatus.previewUrl}>
                <Motion let:motion
                        initial={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 1
                        }}
                        exit={{
                            opacity: 0
                        }}
                        transition={{
                            type: "timing",
                            duration: .5,
                            ease: Easing.easeOutQuint,
                        }}
                >
                    <div use:motion class="pointer-events-none absolute left-0 top-0 right-0 bottom-0">
                        {#if uploadingStatus.previewUrl}
                            {#if uploadingStatus.previewUrl.endsWith(".svg")}
                                <img loading="lazy" src="{uploadingStatus.previewUrl}" alt="upload preview" class="object-contain drop-shadow-md py-7 w-full h-full"/>
                            {:else}
                                <img loading="lazy" src="{uploadingStatus.previewUrl}" alt="upload preview" class="object-cover w-full h-full"/>
                            {/if}
                        {/if}

                        <Motion let:motion
                                initial={{
                                    y: child?.data && uploadingStatus.id === undefined ? "-100%" : "0%",
                                }}
                                animate={{
                                    y: `${Math.round(uploadingStatus.percentage) * -1}%`,
                                }}
                                transition={{
                                    type: "timing",
                                    duration: .35,
                                    ease: Easing.easeOutQuint,
                                }}
                        >
                            <div use:motion class="absolute left-0 top-0 right-0 bottom-0 bg-black/75"></div>
                        </Motion>
                    </div>
                </Motion>
            </AnimatePresence>

            {#if !entry.disabled}
                <AnimatePresence show={draggingFile && uploadingStatus.status === null}>
                    <Motion let:motion
                            initial={{
                                opacity: 0
                            }}
                            animate={{
                                opacity: 1
                            }}
                            exit={{
                                opacity: 0
                            }}
                            transition={{
                                type: "timing",
                                duration: .5,
                                ease: Easing.easeOutQuint,
                            }}
                    >
                        <div use:motion class="pointer-events-none absolute left-0 top-0 right-0 bottom-0">
                            <div class='circle xxlarge shade1'></div>
                            <div class='circle xlarge shade2'></div>
                            <div class='circle large shade3'></div>
                            <div class='circle medium shade4'></div>
                            <div class='circle small shade5'></div>
                        </div>
                    </Motion>
                </AnimatePresence>

                <AnimatePresence show={uploadingStatus.status === null}>
                    <Motion let:motion
                            initial={{
                                opacity: 0
                            }}
                            animate={{
                                opacity: 1
                            }}
                            exit={{
                                opacity: 0
                            }}
                            transition={{
                                type: "timing",
                                duration: .5,
                                ease: Easing.easeOutQuint,
                            }}
                    >
                        <div use:motion class="absolute left-0 top-0 right-0 bottom-0 flex">
                            <div class="flex flex-col items-center justify-center my-auto flex-1 z-10 pointer-events-none select-none">
                                <img alt="upload file icon" class="object-contain mb-3" src="/image/upload.svg"/>
                                <span class="text-east-bay text-sm text-center tracking-tight leading-tight">Přetáhněte sem soubory/obrázky sem <br>nebo klikněte pro výběr</span>
                            </div>
                        </div>
                    </Motion>
                </AnimatePresence>

                <AnimatePresence show={["uploading", "initializing"].includes(uploadingStatus.status)}>
                    <Motion let:motion
                            initial={{
                                opacity: 0
                            }}
                            animate={{
                                opacity: 1
                            }}
                            exit={{
                                opacity: 0
                            }}
                            transition={{
                                type: "timing",
                                duration: .5,
                                ease: Easing.easeOutQuint,
                            }}
                    >
                        <div use:motion class="absolute left-0 top-0 right-0 bottom-0 flex select-none">
                            <div class="text-white w-full flex justify-center mt-auto z-30">
                                <small class="font-semibold italic py-1 bg-black/75 rounded-t-md text-center px-3">
                                    {#if (uploadingStatus.chunk.current + 1) >= uploadingStatus.chunk.total}
                                        Dokončujeme...
                                    {:else}
                                        Nahráváme...
                                    {/if}
                                </small>
                            </div>
                        </div>
                    </Motion>
                </AnimatePresence>

                <AnimatePresence show={uploadingStatus.status === "compressing"}>
                    <Motion let:motion
                            initial={{
                                opacity: 0
                            }}
                            animate={{
                                opacity: 1
                            }}
                            exit={{
                                opacity: 0
                            }}
                            transition={{
                                type: "timing",
                                duration: .5,
                                ease: Easing.easeOutQuint,
                            }}
                    >
                        <div use:motion class="absolute left-0 top-0 right-0 bottom-0 flex flex-col select-none">
                            <div class="flex items-center justify-center flex-1">
                                <img src="/image/puff.svg" class="w-14" alt="busy icon"/>
                            </div>
                            <div class="text-white w-full flex justify-center mt-auto z-30">
                                <small class="font-semibold italic py-1 bg-black/75 rounded-t-md text-center px-3">
                                    Optimalizujeme...
                                </small>
                            </div>
                        </div>
                    </Motion>
                </AnimatePresence>
            {:else if !uploadingStatus.previewUrl}
                <div class="absolute left-0 top-0 right-0 bottom-0 flex">
                    <div class="flex flex-col items-center justify-center my-auto flex-1 z-10 pointer-events-none select-none">
                        <img alt="upload file icon" class="object-contain mb-3 w-10" src="/image/close.svg"/>
                        <span class="text-east-bay text-sm text-center tracking-tight leading-tight">Bez obrázku/souboru</span>
                    </div>
                </div>
            {/if}
        </div>
    </Motion>

    {#if !entry.disabled && entry.help !== null}
        <small class="mt-2">{@html entry.help}</small>
    {/if}

    <FormTypeErrors class="mt-2" errors={entry.errors}/>

    <div class="flex w-full" class:hidden={entry.extra.hide_name_input}>
        <Motion let:motion
                animate={{
                    height: mediaId !== undefined && uploadingStatus.status !== null ? 52 : 0
                }}
                transition={{
                    type: "timing",
                    duration: .35,
                    ease: Easing.easeOutQuint,
                }}
        >
            <div use:motion class="overflow-hidden w-full">
                {#if mediaId !== undefined}
                    <input on:change disabled={entry.disabled ? 'disabled' : ''} placeholder="Název" class="mt-3 rounded-md py-2 w-full focus-visible:outline-0 focus-visible:shadow-md {child?.disabled ? '!bg-transparent' : 'bg-white px-4 shadow-sm'}" name="{entry.children.collection.extra.prototype.children.name.fullName.replace(entry.children.collection.extra.prototype.name, '0')}" type="text" value={mediaName}/>
                {/if}
            </div>
        </Motion>

        {#if mediaId !== undefined}
            <input disabled={entry.disabled ? 'disabled' : ''} name="{entry.children.collection.extra.prototype.children.id.fullName.replace(entry.children.collection.extra.prototype.name, '0')}" tabindex="-1" type="hidden" value={mediaId ?? ""}/>
            <input disabled={entry.disabled ? 'disabled' : ''} name="{entry.children.collection.extra.prototype.children.position.fullName.replace(entry.children.collection.extra.prototype.name, '0')}" tabindex="-1" type="hidden" value={mediaPosition}/>
            <input disabled={entry.disabled ? 'disabled' : ''} name="{entry.children.collection.extra.prototype.children.description.fullName.replace(entry.children.collection.extra.prototype.name, '0')}" tabindex="-1" type="hidden" value={mediaDescription}/>
        {/if}
    </div>
</div>

<style lang="scss">
    .circle {
        @apply bg-white absolute rounded-full;
        animation: ripple 6s infinite ease-in-out;
    }

    .small {
        width: 100px;
        height: 100px;
        left: -50px;
        bottom: -50px;
    }

    .medium {
        width: 200px;
        height: 200px;
        left: -100px;
        bottom: -100px;
    }

    .large {
        width: 300px;
        height: 300px;
        left: -150px;
        bottom: -150px;
    }

    .xlarge {
        width: 400px;
        height: 400px;
        left: -200px;
        bottom: -200px;
    }

    .xxlarge {
        width: 500px;
        height: 500px;
        left: -250px;
        bottom: -250px;
    }

    .shade1 {
        opacity: 0.1;
    }

    .shade2 {
        opacity: 0.2;
    }

    .shade3 {
        opacity: 0.3;
    }

    .shade4 {
        opacity: 0.4;
    }

    .shade5 {
        opacity: 0.5;
    }


    @keyframes ripple {
        0% {
            transform: scale(0.8);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(0.8);
        }
    }

    @keyframes ripple2 {
        0% {
            transform: scale(0.6);
        }

        50% {
            transform: scale(1.0);
        }

        100% {
            transform: scale(0.8);
        }
    }

</style>
